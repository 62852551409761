


import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';

@Component
export default class LineClamper extends Vue {

  public isOpen: boolean = false;
  public clampedText: string = '';
  public isTogglerUnnecessary: boolean = false;

  @Prop({type: String, default: ''})
  public readonly text: string;

  @Prop({type: String, default: '' })
  public readonly closeText: string;

  @Prop({type: String, default: '' })
  public readonly openText: string;

  @Prop({type: Number, default: 0})
  public readonly maxLines: number;

  @Prop({type: Boolean, default: true})
  public readonly useLinkifyUrls: boolean;

  public get togglerText(): string {
    return this.isOpen ? this.closeText : this.openText;
  }

  @Watch('text', { immediate: true })
  private onTextChange(): void {
    this.reset();
    this.$nextTick(() => {
      this.clampText();
    });
  }

  private clampText(): void {
    const SPACE = ' ';
    const lineClamper: Element = this.$refs.lineClamper as Element;
    const lineHeight: number = this.getLineHeight();
    const clampedHeight: number = lineHeight * this.maxLines;
    const visibleWrap: Element = this.$refs.lineClamperVisible as Element;
    const words: string[] = this.text.replace(/\n/g, '\n' + SPACE).trim().split(SPACE);

    visibleWrap.innerHTML = '';

    for (let i = 0; i < words.length; i++) {
      const wordEl: Element = document.createElement('span');
      const nextWord: string = this.useLinkifyUrls ? this.linkifyUrls(words[i]) : words[i];
      wordEl.innerHTML = nextWord + SPACE;
      visibleWrap.appendChild(wordEl);
      if (lineClamper.getBoundingClientRect().height > clampedHeight) {
        visibleWrap.removeChild(wordEl);
        if (this.useLinkifyUrls ) {
          this.clampedText = words.slice(i).map(word => this.linkifyUrls(word)).join(SPACE);
        } else {
          this.clampedText = words.slice(i).join(SPACE);
        }
        break;
      }
    }

    this.checkTogglerNecessity();
  }

  private checkTogglerNecessity(): void {
    this.isTogglerUnnecessary = this.clampedText.length === 0;
  }

  private getLineHeight(): number {
    const lineClamper: Element = this.$refs.lineClamper as Element;
    if (!lineClamper) {
      return null;
    }
    const clamperStyles: CSSStyleDeclaration = window.getComputedStyle(lineClamper);
    return parseFloat(clamperStyles.getPropertyValue('line-height'));
  }

  private reset(): void {
    this.isOpen = false;
    this.isTogglerUnnecessary = false;
    this.clampedText = '';
  }

  private onToggle(): void {
    this.isOpen = !this.isOpen;
  }

  private linkifyUrls(inputString: string): string {
    return ChatHelper.createLinks(inputString);
  }
}
