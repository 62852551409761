


import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import eventDiscoveryService, { TEventDiscoveryServiceConfig } from '@/_services/event-discovery.service';
import { TTextChat } from '@/_types/text-chats/text-chat.type';
import { TContact } from '@/_types/contact.type';
import { TChatGroupState } from '@/_modules/chat/types/chat-group-state.type';
import { TChatMessage } from '@/_modules/chat/types/chat-message.type';
import iconCloseCircleOutline from '@/_modules/icons/components/icon-close-circle-outline.vue';
import chatMessages from '@/_modules/chat/components/chat-messages/chat-messages.vue';
import textChatMessageComposer from '@/_modules/text-chats/components/text-chat-message-composer/text-chat-message-composer.vue';
import lineClamper from '@/_modules/text-chats/components/line-clamper/line-clamper.vue';
import { CHAT_GROUP_PREFIX } from '@/_providers/ew.discovery-service.provider';

const DEFAULT_AVATAR_EVENT = require('@/assets/images/no-avatar-event-60x60.svg');

@Component({
  components: {
    iconCloseCircleOutline,
    chatMessages,
    textChatMessageComposer,
    lineClamper,
  }
})
export default class TextChatExpanded extends Vue {

  @Action('chatStore/configure') public configureChatStore: (config: { eventId: number; contactId: number }) => void;
  @Action('chatStore/enterChatGroup') public enterChatGroup: (groupId: string) => void;
  @Action('chatStore/requestChatGroupMessagesPage') public requestChatGroupMessagesPage: (groupId: string) => void;
  @Getter('chatStore/isConfigured') public readonly isChatStoreConfigured: boolean;
  @Getter('chatStore/textChatsById') public readonly getTextChatById: (textChatId: number) => TTextChat;
  @Getter('chatStore/getChatGroupStateByGroupId') public readonly getChatGroupStateByGroupId: (groupId: string) => TChatGroupState;
  @Getter('promoPageStore/contact') public readonly myself: TContact;
  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName?: string}) => void;

  public isServiceConnected: boolean = false;
  private destroyed$: Subject<void> = new Subject<void>();

  public newMessage: string = '';

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get expandedChatId(): number {
    return this.$route.params.textChatId ? parseInt(this.$route.params.textChatId, 10) : null;
  }

  public get contactIdParam(): number {
    return this.$route.params.contact_id ? parseInt(this.$route.params.contact_id, 10) : null;
  }

  @Watch('contactIdParam', {immediate: true})
  private onContactIdParamChange(newVal: number): void {
    if (!newVal) {
      return;
    }
    this.openContactCardFromUrl();
  }

  public openContactCardFromUrl(): void {
    const contactIdParam: number = parseInt(this.$route.params.contact_id, 10) || null;
    if (contactIdParam) {
      this.openContactCard({
        contactId: contactIdParam,
      });
    }
  }

  public get chatData(): TTextChat {
    return this.getTextChatById(this.expandedChatId);
  }

  public get chatHeadImage(): string {
    if (!this.chatData || !this.chatData.photo_url) {
      return 'url(' + DEFAULT_AVATAR_EVENT + ')';
    }
    return 'url(' + this.chatData.photo_url + ')';
  }

  public get chatHeadName(): string {
    if (!this.chatData || !this.chatData.name) {
      return '';
    }
    return this.chatData.name;
  }

  public get chatHeadDescription(): string {
    if (!this.chatData || !this.chatData.description) {
      return '';
    }
    return this.chatData.description;
  }

  public get chatGroupState(): TChatGroupState {
    const chatGroupId = this.chatGroupId;
    return chatGroupId && this.getChatGroupStateByGroupId(chatGroupId);
  }

  public get chatGroupId(): string {
    if (this.chatData && this.chatData.internal_name) {
      const prefixRemoverRegexp = new RegExp('^' + CHAT_GROUP_PREFIX);
      return this.chatData.internal_name.replace(prefixRemoverRegexp, '');
    }
    return null;
  }

  public get messages(): TChatMessage[] {
    const chatGroupState = this.chatGroupState;
    return (chatGroupState && chatGroupState.messages) || [];
  }

  public get isMessagesLoading(): boolean {
    const chatGroupState = this.chatGroupState;
    return (chatGroupState && chatGroupState.isMessagesLoading) || false;
  }

  public get isAllMessagesLoaded(): boolean {
    const chatGroupState = this.chatGroupState;
    return (chatGroupState && chatGroupState.isAllMessagesLoaded) || false;
  }

  public get isChatGroupEmpty(): boolean {
    return this.chatGroupState
      && this.chatGroupState.messages
      && this.chatGroupState.messages.length === 0
      && !this.chatGroupState.isMessagesLoading;
  }

  public created(): void {
    this.subscribeToServiceEvents();
  }

  public mounted(): void {
    this.initChatStoreConfiguring();
    this.initChatGroup();
  }

  @Watch('chatGroupId')
  private onChatGroupIdChange(): void {
    this.initChatGroup();
  }

  @Watch('isServiceConnected')
  private onIsServiceConnectedChange(newVal: boolean, oldVal: boolean): void {
    if (!oldVal && newVal) {
      this.initChatGroup();
    }
  }

  public beforeDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private initChatStoreConfiguring(): void {
    if (!this.myself) {
      return;
    }

    this.configureChatStore({
      eventId: this.eventId,
      contactId: this.myself.id,
    });
  }

  private initChatGroup(): void {
    if (!this.isServiceConnected) {
      return;
    }
    const chatGroupId = this.chatGroupId;
    const chatGroupState = this.chatGroupState;
    if (chatGroupId && !chatGroupState) {
      this.enterChatGroup(chatGroupId);
      this.requestChatGroupMessagesPage(chatGroupId);
    }
  }

  private subscribeToServiceEvents(): void {
    eventDiscoveryService.connected$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(this.onServiceConnectChange.bind(this));
  }

  private onServiceConnectChange(config: TEventDiscoveryServiceConfig): void {
    this.isServiceConnected = !!config;

    if (this.isServiceConnected) {
      this.initChatGroup();
    }

  }

  private onCloseChatClick(): void {
    this.$router.push({
      name: 'text-chats'
    }).catch(() => {
      /* ignore */
    });
  }

  public async onMessagesScrollTopReached(): Promise<void> {
    const isMessagesLoading = this.isMessagesLoading;
    const isAllMessagesLoaded = this.isAllMessagesLoaded;
    const chatGroupId = this.chatGroupId;

    if (!chatGroupId || isMessagesLoading || isAllMessagesLoaded) {
      return;
    }
    this.requestChatGroupMessagesPage(chatGroupId);
  }

}
